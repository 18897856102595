/* eslint-disable no-unused-vars */
import { Suspense } from 'react';

import SideNav from 'components/organisms/SideNav';
import TopBar from 'common/TopBar';
import Loaders from 'components/atoms/Loaders';
import { Content } from './PageTemplate.styles';

function PageTemplate({ title, showTemplate, children, topBar, merged }) {
  return showTemplate ? (
    <Content>
      <SideNav />
      {topBar && <TopBar title={title} merged={merged} />}
      <Suspense fallback={<Loaders pageLoader />}>{children}</Suspense>
    </Content>
  ) : (
    children
  );
}

export default PageTemplate;
