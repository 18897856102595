import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/atoms/Button';
import { WizardContainer, WizardStep, WizardFooter } from './Wizerd.styles';

function Wizard({ steps, nextConditions = [], onDone, loading = false }) {
  const [currentStep, setCurrentStep] = useState(0);

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <WizardContainer>
      <WizardStep>{steps[currentStep]}</WizardStep>
      <WizardFooter>
        {currentStep !== 0 && (
          <Button onClick={handlePrevious} xs type="primary" loading={loading}>
            Previous
          </Button>
        )}
        <Button
          onClick={currentStep < steps.length - 1 ? handleNext : onDone}
          xs
          type="primary"
          loading={loading}
          disabled={!nextConditions[currentStep] || loading}>
          {currentStep < steps.length - 1 ? 'Next' : 'Done'}
        </Button>
      </WizardFooter>
    </WizardContainer>
  );
}

Wizard.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default Wizard;
