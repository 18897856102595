import { useEffect, useMemo } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { SideNavContext } from 'context/sideNavContext';
import { LoadingContext } from 'context/loadingContext';
import LogoFull from 'assets/images/psp.jpg';
import LogoSmall from 'assets/images/psp-logo.svg';
import { AuthContext } from 'context/authContext';
import UserProfileActions from 'components/organisms/UserProfileActions';
import sideNavData from 'nav.json';
import { useContextHook } from 'use-context-hook';
import { SideNavbar, Nav, Ul, CloseButton, LogoHolder, Logo } from './SideNav.styles';
import SubMenu from './SubMenu';

function Navbar() {
  const { toggleSideNav, sideNavState } = useContextHook(SideNavContext, ['toggleSideNav', 'sideNavState']);
  const { isLoading } = useContextHook(LoadingContext, ['isLoading']);
  const { allowedPages } = useContextHook(AuthContext, ['allowedPages']);

  const MaxWidth991 = useMediaPredicate('(max-width: 991px)');

  useEffect(() => {
    if (!sideNavState) {
      document.body.classList.remove('nav-active');
    }
  }, [sideNavState]);

  const sideBarItems = useMemo(
    () =>
      sideNavData
        .filter(
          ({ file, live }) =>
            allowedPages.includes(file) && (process?.env?.REACT_APP_MAIN_URL === 'https://plastk.ca' ? live : true),
        )
        .map(item => <SubMenu item={item} key={item.name} />),
    [isLoading, sideNavData, allowedPages],
  );
  return (
    <SideNavbar css={isLoading && 'background:var(--dark);'} $loading={isLoading}>
      <LogoHolder>
        <Logo to="/">
          <img src={LogoSmall} alt="plastk" className="logo-small" />
          <img src={LogoFull} alt="plastk" className="logo-full" />
        </Logo>
      </LogoHolder>
      {MaxWidth991 && (
        <CloseButton onClick={toggleSideNav}>
          <i className="material-icons-outlined">close</i>
        </CloseButton>
      )}

      <Nav>
        <Ul>{sideBarItems}</Ul>
      </Nav>
      <UserProfileActions
        toggleSideNav={toggleSideNav}
        profile={sideBarItems.filter(item => item.props.item.name === 'Profile')}
      />
    </SideNavbar>
  );
}
export default Navbar;
