import styled from 'styled-components/macro';

export const WizardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WizardStep = styled.div`
  margin-bottom: 20px;
`;

export const WizardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  gap: 400px;
  button {
    width: 100px;
    height: 40px;
  }
`;
