import { useState } from 'react';
import 'styled-components/macro';
import { SideNavContext } from 'context/sideNavContext';
import { AuthContext } from 'context/authContext';
import Avatar from 'assets/images/avatar-img01.png';
import { useContextHook } from 'use-context-hook';
import {
  ProfileHolder,
  UserWrap,
  ImgBox,
  TextBox,
  DropDown,
  Ul,
  Li,
  StyledLink,
  Name,
  Text,
  IconHolder,
} from './UserProfileActions.styles';

function UserProfileActions({ profile }) {
  const { toggleSideNav } = useContextHook(SideNavContext, ['toggleSideNav']);
  const { onLogout, user } = useContextHook(AuthContext, ['onLogout', 'user']);

  const [isOpen, setIsOpen] = useState(false);

  return (
    <ProfileHolder>
      <UserWrap onClick={() => setIsOpen(!isOpen)}>
        <ImgBox>
          <img src={Avatar} width="40" height="40" alt="user" />
        </ImgBox>

        <TextBox>
          <Name>{user?.email?.split('@')[0]}</Name>
        </TextBox>
        <i className="icon-chevron-down material-icons-outlined">expand_more</i>
      </UserWrap>

      <DropDown dropdownOpen={isOpen}>
        <Ul>
          <Li index="1">
            {profile.map((fileName, index) => (
              <StyledLink
                to={`/pos/${fileName.props.item.file}`}
                key={index}
                onClick={() => {
                  toggleSideNav();
                }}>
                <IconHolder>
                  <i className="material-icons-outlined">person</i>
                </IconHolder>
                <Text>Profile</Text>
                <i className="material-icons-outlined">chevron_right</i>
              </StyledLink>
            ))}
          </Li>
          <Li index="2">
            <StyledLink
              as="span"
              css="cursor: pointer;"
              onClick={e => {
                e.preventDefault();
                toggleSideNav();
                onLogout();
              }}>
              <IconHolder>
                <i className="material-icons-outlined">logout</i>
              </IconHolder>
              <Text>Logout</Text>
            </StyledLink>
          </Li>
        </Ul>
      </DropDown>
    </ProfileHolder>
  );
}

export default UserProfileActions;
