import 'styled-components/macro';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { LoadingContext } from 'context/loadingContext';
import Header from 'components/molecules/Header';
import Button from 'components/atoms/Button';
import topNavData from 'nav.json';
import { useParams } from 'react-router-dom';
import ModalContainer from 'components/molecules/ModalContainer';
import PermissionForm from 'components/organisms/PermissionsForm';
import adminService from 'services/api';
import Toast from 'components/molecules/Toast';
import RolesForm from 'components/organisms/RolesForm';
import AdminForm from 'components/organisms/AdminForm';
import { useContextHook } from 'use-context-hook';
import DownloadQr from 'components/organisms/DownloadQr';
import CreateQr from 'components/organisms/CreateQr';
import { usePermissions } from 'hooks/usePermissions';
import UploadMerchantCards from 'components/organisms/UploadMerchantCards';

function TopBar({ merged }) {
  const { isLoading } = useContextHook(LoadingContext, ['isLoading']);
  const { hasPermission } = usePermissions();

  const { view: title } = useParams();

  const { buttons = [] } = topNavData.find(({ file }) => file === title);

  const restorePermissions = async () => {
    try {
      const res = await adminService.restorePermissions();
      if (res) {
        Toast({
          type: 'success',
          message: 'Permissions restored successfully',
        });
      }
    } catch (ex) {
      Toast({
        type: 'error',
        message: ex?.message,
      });
    }
  };

  return merged ? (
    <Header title="dashboard" />
  ) : (
    <Header title={title.split('-').join(' ')}>
      {isLoading ? (
        <Skeleton rectangle height={40} width={131} css="border-radius:8px !important;" />
      ) : (
        <>
          {buttons.includes('admins.create') && hasPermission('admins.create') && (
            <ModalContainer
              lg
              title="Create Admin"
              btnComponent={({ onClick }) => (
                <Button
                  type="primary"
                  onClick={onClick}
                  iconMobile
                  prefix={<i className="material-icons-outlined">add</i>}>
                  <span className="text">Create Admin</span>
                </Button>
              )}
              content={({ onClose }) => <AdminForm onClose={onClose} />}
            />
          )}

          {buttons.includes('permissions.create') && hasPermission('permissions.create') && (
            <ModalContainer
              lg
              title="Create Permission"
              btnComponent={({ onClick }) => (
                <Button
                  type="primary"
                  onClick={onClick}
                  iconMobile
                  prefix={<i className="material-icons-outlined">add</i>}>
                  <span className="text">Create Permission</span>
                </Button>
              )}
              content={({ onClose }) => <PermissionForm onClose={onClose} />}
            />
          )}

          {buttons.includes('permissions.restore') && hasPermission('permissions.restore') && (
            <Button
              type="outline"
              onClick={restorePermissions}
              iconMobile
              prefix={<i className="material-icons-outlined">restore</i>}>
              <span className="text">Restore Permissions</span>
            </Button>
          )}

          {buttons.includes('roles.create') && hasPermission('roles.create') && (
            <ModalContainer
              lg
              title="Create Role"
              btnComponent={({ onClick }) => (
                <Button
                  type="primary"
                  onClick={onClick}
                  iconMobile
                  prefix={<i className="material-icons-outlined">add</i>}>
                  <span className="text">Create Role</span>
                </Button>
              )}
              content={({ onClose }) => <RolesForm onClose={onClose} />}
            />
          )}
          {buttons.includes('create-qr') && hasPermission('dashboard.nav') && (
            <ModalContainer
              sm
              title="Create QR"
              btnComponent={({ onClick }) => (
                <Button
                  type="primary"
                  onClick={onClick}
                  iconMobile
                  prefix={<i className="material-icons-outlined">add</i>}>
                  <span className="text">Create Qr</span>
                </Button>
              )}
              content={({ onClose }) => <CreateQr onClose={onClose} />}
            />
          )}
          {buttons.includes('download-qr') && hasPermission('dashboard.nav') && (
            <ModalContainer
              title="Download Qr Codes"
              lg
              btnComponent={({ onClick }) => (
                <Button
                  type="primary"
                  onClick={onClick}
                  iconMobile
                  prefix={<i className="material-icons-outlined">download</i>}>
                  <span className="text">Download Qr Codes</span>
                </Button>
              )}
              content={({ onClose }) => <DownloadQr onClose={onClose} />}
            />
          )}
          {buttons.includes('upload-cards') && (
            <ModalContainer
              title="Upload Cards"
              lg
              btnComponent={({ onClick }) => (
                <Button
                  type="primary"
                  onClick={onClick}
                  iconMobile
                  prefix={<i className="material-icons-outlined">upload</i>}>
                  <span className="text">Upload Cards</span>
                </Button>
              )}
              content={({ onClose }) => <UploadMerchantCards onClose={onClose} />}
            />
          )}
        </>
      )}
    </Header>
  );
}

export default TopBar;
