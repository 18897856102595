/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
import Button from 'components/atoms/Button';
import Select from 'components/atoms/Select';
import Field from 'components/molecules/Field';
import Form, { useForm } from 'components/molecules/Form';
import Toast from 'components/molecules/Toast';
import { AuthContext } from 'context/authContext';
import api from 'services/api';
import React, { useState } from 'react';
import { useContextHook } from 'use-context-hook';
import Switch from 'components/atoms/Switch';
import { StyledAlert } from 'components/molecules/Toast/Toast.styles';

function CreateQr({ onClose = () => {}, data }) {
  const [loading, setLoading] = useState(false);
  const { refetch } = useContextHook(AuthContext, {
    refetch: 1,
  });
  const [state, setState] = useState({ isGlobal: false });
  const [form] = useForm();
  const handleSubmit = async val => {
    try {
      const payload = {
        qr_length: val?.qr_length,
        amount: val.amount,
        business_name: val?.business?.value,
        business_no: val?.business?.business_no,
        business_id: val?.business?.business_id,
        isGlobal: val?.isGlobal,
      };
      setLoading(true);
      const res = !data ? await api.createQr(payload) : await api.updateQr(data?._id, val);
      Toast({ type: 'success', message: res?.message });
      onClose();
      setLoading(false);
      refetch();
    } catch (error) {
      Toast({ type: 'error', message: error?.message });
      setLoading(false);
    }
  };
  const handlePortalUserSearch = async __ => {
    try {
      const response = await api.getPspBusinesses({
        page: 1,
        pageSize: 10,
        searchText: __,
      });
      const options = response?.data?.items?.map(_ => ({
        value: _?.title,
        label: _?.title,
        business_no: _?.bid,
        business_id: _?._id,
      }));

      return options;
    } catch (ex) {
      Toast({
        type: 'error',
        message: ex.message,
      });
      return [];
    }
  };
  return (
    <Form form={form} onSubmit={handleSubmit} onTouched={_ => setState(__ => ({ ...__, ..._ }))}>
      {!data?.card_number && (
        <>
          <Form.Item
            label="Business"
            name="business"
            placeholder="Select Business"
            type="select"
            async
            defaultOptions
            rules={[{ required: true, message: 'Please select business' }]}
            loadOptions={handlePortalUserSearch}
            filterOption={false}>
            <Select />
          </Form.Item>
          <Form.Item
            sm
            type="number"
            label="No. Of Qr to Generate"
            name="qr_length"
            placeholder="Enter no. of qr to generate"
            rules={[
              { required: true, message: 'This Field is Required' },
              { min: 0, message: 'Value must be  greater than 0' },
              { max: 500, message: 'Value must be  less than or equal to 500' },
            ]}>
            <Field />
          </Form.Item>
        </>
      )}
      <Form.Item name="isGlobal" label="Global">
        <Switch />
      </Form.Item>
      <Button type="primary" htmlType="submit" loading={loading}>
        {data ? 'Update' : 'Create'}
      </Button>
      {state.isGlobal && (
        <StyledAlert $type="info">
          <p>
            <strong>Global QR </strong> can be used by all businesses
          </p>
        </StyledAlert>
      )}
    </Form>
  );
}
export default CreateQr;
