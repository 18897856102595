import { formatCardNumber } from 'helpers/common';
import React from 'react';

function GiftCards({ records }) {
  return (
    <div
      className="card-holder"
      style={{
        position: 'absolute',
        left: '-9999px',
        bottom: '0',
      }}>
      <div
        className="psp-front"
        id="psp-front"
        style={{
          width: '325px',
          height: '200px',
          marginBottom: '15px',
        }}>
        <div className="psp-card">
          <div className="box">
            <span className="title">PSP GIFT CARDS</span>
            <span className="subtitle">Payment Service Providers, Inc.</span>
          </div>
        </div>
      </div>
      {records?.length &&
        records.map(itm => (
          <div
            id={itm?._id}
            style={{
              borderRadius: '10px',
              width: '325px',
              height: '200px',
            }}
            key={itm?._id}>
            <div className="psp-card-back">
              <div className="holder">
                <div className="qr-code">
                  <div className="qr-img">
                    <img src={itm?.base64} width="70" alt="qrcode" />
                  </div>
                  <div className="text">
                    <p>
                      Use of this card is subject to the terms of the agreement of which Cardholder acknowledges receipt
                      by such use. &apos;PSP Rewards, Licensed User. L&apos;utilisation de cette carte est soumise aux
                      termes de l&apos;accord dont le titulaire de la carte accuse réception d&apos;une telle
                      utilisation. PSP Rewards, Utilisateur sous licence.
                    </p>
                  </div>
                </div>

                <div className="bar-code">
                  <img src="/bar-code-img.png" width="150" alt="barcode" />
                  <span>{itm?.serial_number}</span>
                </div>
                <div className="wrap">
                  <span className="card-number">{formatCardNumber(itm?.card_number)}</span>

                  <div className="pin-code">
                    <span className="pin-number">{itm?.pin}</span>
                    <img src="/pin-code-img.jpg" width="20" height="5" alt="pin" />
                  </div>
                </div>
                <span className="font-small">
                  <img src="/psp-logo-sm.png" width="7" alt="psp-logo-sm" />
                  Powered By Payment Service Providers, Inc
                </span>
              </div>
              <div className="inquiries">
                <span className="inquiries-text">For inquiries: 1800 848 2974</span>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}

export default GiftCards;
