/* eslint-disable no-nested-ternary */
import 'styled-components/macro';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { TableRow } from 'components/atoms/TableRow';
import TableCell from 'components/atoms/TableCell';
import { StyledTable, TableHolder, TBody, TableScroll, Thead, NoRecordFound } from './Table.styles';

function Table({
  loading,
  columnNames,
  rowsData,
  height,
  center,
  sm,
  headSm,
  onClick = () => {},
  noPadding,
  width,
  responsive = true,
  changeColor = () => false,
  ...props
}) {
  return (
    <TableHolder responsive={responsive} noPadding={noPadding}>
      <TableScroll $height={height}>
        <StyledTable $width={width} responsive={responsive} {...props}>
          <Thead responsive={responsive}>
            <TableRow responsive={responsive}>
              {columnNames.map((item, index) =>
                item === 'del' ? null : (
                  <TableCell responsive={responsive} heading key={index} center={center} headSm={headSm}>
                    {item}
                  </TableCell>
                ),
              )}
            </TableRow>
          </Thead>
          <TBody responsive={responsive}>
            {loading ? (
              Array(5)
                .fill()
                .map((item, i) => (
                  <TableRow
                    key={i}
                    responsive={responsive}
                    {...(() => {
                      if (!changeColor(item)) return {};
                      return { style: { backgroundColor: 'red' } };
                    })()}>
                    {columnNames?.map(index => (
                      <TableCell responsive={responsive} key={index}>
                        <Skeleton width="100%" height={15} />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
            ) : rowsData?.length ? (
              rowsData?.map((row, index) => (
                <TableRow
                  {...(() => {
                    if (!changeColor(row)) return {};
                    return { style: { backgroundColor: 'red' } };
                  })()}
                  key={index}
                  onClick={() => onClick(row)}
                  responsive={responsive}
                  deleted={row?.includes('deleted')}>
                  {row?.map((el, i) =>
                    el === 'deleted' || el === 'false' ? null : (
                      <TableCell responsive={responsive} data-th={columnNames[i]} key={i} center={center} sm={sm}>
                        {el}
                      </TableCell>
                    ),
                  )}
                </TableRow>
              ))
            ) : (
              <tr
                css={`
                  @media (max-width: 991px) {
                    grid-column: span 2 / span 2;
                  }
                `}>
                <td
                  colSpan={columnNames?.length}
                  className="text-center"
                  css={`
                    @media (max-width: 991px) {
                      display: block;
                    }
                  `}>
                  <NoRecordFound>No Record Found</NoRecordFound>
                </td>
              </tr>
            )}
          </TBody>
        </StyledTable>
      </TableScroll>
    </TableHolder>
  );
}

export default Table;
