/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import Button from 'components/atoms/Button';
import Grid from 'components/atoms/Grid';
import Heading from 'components/atoms/Heading';
import Select from 'components/atoms/Select';
import Field from 'components/molecules/Field';
import Form, { useForm } from 'components/molecules/Form';
import Toast from 'components/molecules/Toast';
import { AuthContext } from 'context/authContext';
import { useContextHook } from 'use-context-hook';
import api from 'services/api';
import JSZip from 'jszip';
import * as htmlToImage from 'html-to-image';
import { createRoot } from 'react-dom/client';
import { BtnWrap } from './DownloadQr.styles';
import GiftCards from '../GiftCards';

function DownloadQr({ onClose = () => {} }) {
  const [loading, setLoading] = useState(false);
  const { refetch } = useContextHook(AuthContext, { refetch: 1 });
  const [state, setState] = useState({ dateRange: [null, null], qr_codes: [] });
  const [startDate, endDate] = state.dateRange;
  const [form] = useForm();
  const qrRef = useRef([]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const payload = {
        start_date: startDate,
        end_date: endDate,
        business_no: state?.business?.business_no,
        batch_id: state?.batch?.value,
        re_download: !!state?.re_download,
      };
      const res = await api.printQr(payload);
      if (res?.data?.downloaded) {
        setState(prev => ({ ...prev, showConfirmModal: true, re_download: true }));
        Toast({ type: 'error', message: "Some of the Qr's have already been downloaded" });
        return;
      }
      const rootElement = document.getElementById('psp-cardsss');

      // Use createRoot to render your GiftCards component
      const root = createRoot(rootElement);
      root.render(<GiftCards records={res.data} />);
      qrRef.current = res.data;
      setState(prev => ({ ...prev, qr_codes: res.data }));
      const base64Images = [];
      setTimeout(async () => {
        const front_card_node = document.getElementById('psp-front');
        const dataUrlFront = await htmlToImage.toPng(front_card_node, {
          quality: 1,
          height: front_card_node.clientHeight,
          width: front_card_node.clientWidth,
        });
        base64Images.push({ name: `card_front`, value: dataUrlFront });

        await Promise.all(
          res.data?.map(async itm => {
            const node = document.getElementById(itm?._id);
            const dataUrl = await htmlToImage.toPng(node, {
              quality: 1,
              height: node.clientHeight,
              width: node.clientWidth,
            });

            base64Images.push({ name: `${itm?.card_number}_back`, value: dataUrl });
          }),
        );
        const zip = new JSZip();
        base64Images.forEach(itm => {
          const binaryData = atob(itm?.value.split(',')[1]);
          const arrayBuffer = new ArrayBuffer(binaryData.length);
          const uint8Array = new Uint8Array(arrayBuffer);
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < binaryData.length; i++) {
            uint8Array[i] = binaryData.charCodeAt(i);
          }

          // Add the image to the zip file with a unique name
          zip.file(`${itm?.name}.png`, uint8Array, { binary: true });
        });

        // Generate the zip file
        const content = await zip.generateAsync({ type: 'blob' });

        // Create a download link and trigger the download
        const link = document.createElement('a');
        link.href = URL.createObjectURL(content);
        link.download = `qr_${new Date().getTime()}`;
        link.click();
        setLoading(false);

        onClose();
        Toast({
          type: 'success',
          message: res?.message,
        });
        refetch();
      }, 500);
    } catch (ex) {
      setLoading(false);

      Toast({
        type: 'error',
        message: ex?.message,
      });
    }
  };
  const handlePortalUserSearch = async __ => {
    try {
      const response = await api.getPspBusinesses({
        page: 1,
        pageSize: 10,
        searchText: __,
      });
      const options = response?.data?.items?.map(_ => ({
        value: _?.title,
        label: _?.title,
        business_no: _?.bid,
        business_id: _?._id,
      }));

      return options;
    } catch (ex) {
      Toast({
        type: 'error',
        message: ex.message,
      });
      return [];
    }
  };
  const handleBatchesSearch = async __ => {
    try {
      const response = await api.getQrBatches({
        page: 1,
        pageSize: 10,
        searchText: __,
      });

      return response?.data?.items ?? [];
    } catch (ex) {
      Toast({
        type: 'error',
        message: ex.message,
      });
      return [];
    }
  };

  function ReConfirmModal() {
    return (
      <>
        <Heading level={4}>
          Some of the QR&quot;s are already been downloaded. Are you sure you want to download them again?
        </Heading>
        <BtnWrap>
          <Button
            type="outline"
            htmlType="submit"
            onClick={() => {
              setState(prev => ({ ...prev, showConfirmModal: false, re_download: false }));
            }}>
            No
          </Button>
          <Button
            type="danger"
            htmlType="submit"
            loading={loading}
            onClick={() => {
              setState(prev => ({ ...prev, re_download: true }));
              setLoading(true);
              handleSubmit();
            }}>
            Yes
          </Button>
        </BtnWrap>
      </>
    );
  }
  return !state?.showConfirmModal ? (
    <Form form={form} onSubmit={handleSubmit} onTouched={_ => setState(__ => ({ ...__, ..._ }))}>
      <Grid xs={1} lg={2} colGap={20}>
        <Form.Item
          label="Business"
          name="business"
          placeholder="Select Business"
          type="select"
          async
          defaultOptions
          rules={[{ required: false, message: 'Please select Business' }]}
          loadOptions={handlePortalUserSearch}
          filterOption={false}>
          <Select />
        </Form.Item>
        <Form.Item
          prefix={<i className="material-icons-outlined">date_range</i>}
          placeholderText="Select date range"
          type="datepicker"
          label="Date Range"
          name="dateRange"
          noMargin
          selectsRange
          clear={startDate || endDate}
          startDate={startDate}
          endDate={endDate}
          onChange={({ target: { value } }) => {
            setState(prev => ({ ...prev, dateRange: value }));
            form.setFieldsValue({ dateRange: value });
          }}
          rules={[{ required: false, message: 'This field is required' }]}>
          <Field />
        </Form.Item>
        <Form.Item
          label="Select Batch"
          name="batch"
          placeholder="Select Batch"
          type="select"
          async
          defaultOptions
          rules={[{ required: false, message: 'Please select Batch' }]}
          loadOptions={handleBatchesSearch}
          filterOption={false}>
          <Select />
        </Form.Item>
      </Grid>
      <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
        Download
      </Button>
    </Form>
  ) : (
    <ReConfirmModal />
  );
}

export default DownloadQr;
