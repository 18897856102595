/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import Select from 'components/atoms/Select';
import TableLayout from 'components/atoms/TableLayout';
import Table from 'components/molecules/Table';
import Toast from 'components/molecules/Toast';
import { StyledAlert } from 'components/molecules/Toast/Toast.styles';
import Wizard from 'components/molecules/Wizard';
import React from 'react';
import API from 'services/api';
import styled from 'styled-components/macro';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin: 20px;
  justify-content: center;
`;

const Item = styled.div`
  width: 300px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    transform: scale(1.05);
  }
`;

function ItemComp({ materialIcon, title, children, iconClick, ...props }) {
  return (
    <Item {...props}>
      <span onClick={iconClick} className="material-icons">
        {materialIcon}
      </span>
      <h2>{title}</h2>
      {children}
    </Item>
  );
}

export default function UploadMerchantCards({ onClose }) {
  const [loading, setLoading] = React.useState(false);
  const [state, setState] = React.useState({
    business: null,
    csv_file: null,
  });
  const handlePortalUserSearch = async __ => {
    try {
      const response = await API.getPspBusinesses({
        page: 1,
        pageSize: 10,
        searchText: __,
      });
      const options = response?.data?.items?.map(_ => ({
        value: _?.title,
        label: _?.title,
        business_no: _?.bid,
        business_id: _?._id,
      }));

      return options;
    } catch (ex) {
      Toast({
        type: 'error',
        message: ex.message,
      });
      return [];
    }
  };
  const downloadCsv = async () => {
    try {
      const csvHeaders = ['business_name', 'card_number', 'status', 'balance', 'pin'];
      const csvData = [[state?.business?.value, '', 'active/new', 0, '1234']];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 20; i++) {
        csvData.push(csvData[0]);
      }
      const csv = [csvHeaders, ...csvData].map(row => row.join(',')).join('\n');
      const blob = new Blob([csv], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${state.business.value}_template.csv`;
      a.click();
    } catch (ex) {
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };
  const uploadCsv = async ({ target: { files } }) => {
    try {
      const file = files[0];
      if (!file) {
        return;
      }
      const reader = new FileReader();
      reader.onload = async e => {
        const text = e.target.result;
        const rows = text.split('\n');
        const headers = rows[0].split(',');
        if (
          headers.length !== 5 ||
          !headers.every(_ =>
            ['business_name', 'card_number', 'status', 'balance', 'pin'].includes(String(_).replace(/[\r\n]/g, '')),
          )
        ) {
          Toast({
            type: 'error',
            message: 'Invalid CSV file, please download the template and fill in the details',
          });
          setState({ ...state, is_csv_ok: false });
          return;
        }
        const csvData = rows
          .slice(1)
          .map(row => row.split(','))
          .filter(_ => _.length === 5);
        const conditions = {
          business_name: state.business.value,
          card_number: { $ne: '', $gte: '10' },
          status: { $in: ['active', 'new'] },
          balance: { $gte: 0 },
          pin: { $gte: 0 },
        };
        const errors = [];

        const is_csv_ok = csvData.every(row =>
          Object.keys(conditions).every((key, index) => {
            if (conditions[key].$ne && String(row[index]) === conditions[key].$ne) {
              errors.push(`Row ${JSON.stringify(row)}: ${key} should not be empty`);
              return false;
            }
            if (conditions[key].$gte && String(row[index]).length < conditions[key].$gte) {
              errors.push(`Row ${JSON.stringify(row)}: ${key} should be greater than ${conditions[key].$gte}`);
              return false;
            }
            if (conditions[key].$in && !conditions[key].$in.includes(String(row[index]))) {
              errors.push(`Row ${JSON.stringify(row)}: ${key} should be one of ${conditions[key].$in}`);
              return false;
            }
            return true;
          }),
        );
        setState({ ...state, csv_file: file, csvData, is_csv_ok });
      };
      reader.readAsText(file);
    } catch (ex) {
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };
  function SelectBusiness() {
    return (
      <Select
        label="Select a Business to upload cards for"
        name="business"
        placeholder="Select Business"
        type="select"
        async
        defaultOptions
        loadOptions={handlePortalUserSearch}
        filterOption={false}
        value={state.business}
        onChange={({ target: { value } }) => setState({ ...state, business: value })}
      />
    );
  }
  function DownloadTemplateOrUploadCsv() {
    return (
      <>
        <StyledAlert $type="info">
          Please download the template and fill in the details Then upload the CSV file to upload the cards for the
          selected business.
        </StyledAlert>
        <Container>
          <ItemComp materialIcon="cloud_download" title={`${state.business.value} Template`} onClick={downloadCsv} />
          {!state?.csvData ? (
            <ItemComp
              materialIcon="cloud_upload"
              title={
                <label htmlFor="file-upload">
                  Upload {state.business.value} CSV
                  <input type="file" id="file-upload" style={{ display: 'none' }} onChange={uploadCsv} />
                </label>
              }
            />
          ) : (
            <ItemComp
              materialIcon="restore"
              iconClick={() => setState({ ...state, csvData: null, is_csv_ok: null })}
              title={
                <span
                  style={{
                    fontSize: '7rem',
                    color: state.is_csv_ok ? 'green' : 'red',
                  }}
                  className="material-icons">
                  cloud_done
                </span>
              }
            />
          )}
        </Container>
      </>
    );
  }
  function ShowUploadedCsv() {
    const [searchQuery, setSearchQuery] = React.useState({
      page: 1,
      pageSize: 10,
      searchText: '',
    });
    const total_data = state?.csvData?.length || 0;
    const totalCount = total_data;

    const columnNames = ['Business Name', 'Card Number', 'Status', 'Balance', 'Pin'];

    const rowsData = state?.csvData
      ?.filter(_ => String(_[1]).toLowerCase().includes(searchQuery?.searchText?.toLowerCase()))
      ?.slice((searchQuery.page - 1) * searchQuery.pageSize, searchQuery.page * searchQuery.pageSize)
      .map(_ => [_[0], _[1], _[2], _[3], _[4]]);

    return (
      <>
        <TableLayout
          customFilterKey="dashboard"
          onChangeFilters={filters => {
            setSearchQuery(_ => ({
              ..._,
              ...filters,
            }));
          }}
          currentPage={searchQuery.page}
          totalCount={totalCount}
          pageSize={searchQuery.pageSize}>
          <Table
            rowsData={rowsData}
            columnNames={columnNames}
            noPadding
            // eslint-disable-next-line arrow-body-style, no-unused-vars
            changeColor={row => {
              if (row[0] !== state.business.value) {
                return true;
              }
              if (row[1] === '' || row[1] < 10) {
                return true;
              }
              if (row[2] !== 'active' && row[2] !== 'new') {
                return true;
              }
              if (row[3] < 0) {
                return true;
              }
              if (row[4] < 0) {
                return true;
              }
              return false;
            }}
          />
        </TableLayout>
        {!state.is_csv_ok && (
          <StyledAlert $type="error">
            The CSV file is not valid. Please download the template and fill in the details Then upload the CSV file to
            upload the cards for the selected business.
          </StyledAlert>
        )}
      </>
    );
  }
  const onSave = async () => {
    try {
      setLoading(true);
      const json = state.csvData.map(_ => ({
        business_name: _[0],
        business_id: state.business.business_id,
        business_no: state.business.business_no,
        card_number: _[1],
        status: _[2],
        balance: _[3],
        pin: _[4],
      }));
      await API.uploadMerchantCards(json);
      Toast({
        type: 'success',
        message: 'Cards uploaded successfully',
      });

      onClose();
      setLoading(false);
    } catch (ex) {
      setLoading(false);
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };
  return (
    <Wizard
      steps={[<SelectBusiness />, <DownloadTemplateOrUploadCsv />, <ShowUploadedCsv />]}
      nextConditions={[state?.business?.value, state?.csv_file, state?.is_csv_ok]}
      onDone={onSave}
      loading={loading}
    />
  );
}
