import 'styled-components/macro';
import { AuthContext } from 'context/authContext';
import Button from 'components/atoms/Button';
import { useContextHook } from 'use-context-hook';
import LoginTemplate from '../../templates/LoginTemplate';
import Field from '../../molecules/Field';
import Form, { useForm } from '../../molecules/Form';
import { StyledForm } from './Login.styles';

function Login() {
  const [form] = useForm();
  const { onLogin, user_loading } = useContextHook(AuthContext, ['onLogin', 'user_loading']);

  return (
    <LoginTemplate title="Sign In" subtitle="Hello there! Sign in and start managing user accounts.">
      <StyledForm form={form} onSubmit={onLogin}>
        <Form.Item
          type="text"
          label="Email"
          name="email"
          placeholder="Your Email"
          prefix={<i className="material-icons-outlined">email</i>}
          rules={[{ required: true }, { pattern: /^.{0,256}$/, message: 'Maximum Character Length is 256' }]}>
          <Field />
        </Form.Item>
        <Form.Item
          type="password"
          label="Password"
          name="password"
          placeholder="Your Password"
          prefix={<i className="material-icons-outlined">lock</i>}
          rules={[
            {
              required: true,
            },
            { pattern: /^.{8,64}$/, message: 'Minimum Character Length is 8 and Maximum Character Length is 64' },
          ]}>
          <Field />
        </Form.Item>

        <Button loading={user_loading} type="primary" htmlType="submit" width={150} css="margin: 0 auto;">
          Sign in
        </Button>
      </StyledForm>
    </LoginTemplate>
  );
}

export default Login;
